import React from "react";
import "../utils/normalize.css";
import "../utils/css/screen.css";
import { useIntl } from "react-intl";
import { useForm } from "@formcarry/react";


export default function Form() {
  const intl = useIntl();

  const { state, submit } = useForm({
    id: "9HT-3GRkk2"
  });

  // Success message
  if (state.submitted) {
    return <h2 id="this-is-a-section">{intl.formatMessage({ id: "tskler" })}</h2>;
  }

  return (
    <form onSubmit={submit}>
      <div className="row gtr-uniform">
        <div className="col-6 col-12-xsmall">
          <input
            type="text"
            name={intl.formatMessage({ id: "name" })}
            id="name"
            placeholder={intl.formatMessage({ id: "name" })}
          />
        </div>
        <div className="col-6 col-12-xsmall">
          <input
            type="email"
            name={intl.formatMessage({ id: "email" })}
            id="email"
            placeholder={intl.formatMessage({ id: "email" })}
          />
        </div>
        {/* Break */}
        <div className="col-12">
                <textarea
                  name={intl.formatMessage({ id: "message" })}
                  id="message"
                  placeholder={intl.formatMessage({ id: "message" })}
                  rows={6}
                />
        </div>
        {/* Break */}
        <div className="col-12">
          <ul className="actions">
            <li>
              <button
                type="submit"
                className="primary"
              >
                {intl.formatMessage({ id: "submit" })}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
}
