import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HalfHeader from "../components/halfBGImage"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import { useIntl } from "react-intl"
import Form from "../components/form";

function ElementsPage ({ data }, props)  {
  const siteTitle = data.site.siteMetadata.title
  const intl = useIntl()



  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={intl.formatMessage({ id: "nav6" })}
        keywords={[`pianist`, `prodigy`, `classical musician`, `piano`]}
      />

      <HalfHeader data={data} title={intl.formatMessage({ id: "nav6" })} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">

          <h2 id="this-is-a-section">
            {intl.formatMessage({ id: "contact" })}
          </h2>
          <h4 style={{textAlign:"center"}} id="this-is-a-section">
            {intl.formatMessage({ id: "viaemail" })}
          </h4>
          <h4 style={{textAlign:"center"}}  id="this-is-a-section">
            <a style={{color: "#474747"}} href="mailto:info@ilyunburkev.com">info@ilyunburkev.com</a>
          </h4>
          <h4 style={{textAlign:"center"}} id="this-is-a-section">
            {intl.formatMessage({ id: "fillform" })}
          </h4>
          <hr />
          <Form/>
        </div>
      </article>

    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mobileImage: file(relativePath: { eq: "ilyun-burkev-23-desktop-bg-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 490, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    desktopImage: file(relativePath: { eq: "ilyun-burkev-23-desktop-bg-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
